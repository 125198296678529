import React, { useEffect, useRef, useState } from 'react'
import { ReactComponent as PlayIcon } from '../../assets/icons/play.svg'
import { ReactComponent as PauseIcon } from '../../assets/icons/pause.svg'
import starWarsTheme from '../../assets/audio/star_wars_theme.mp3'

import cls from './header.module.css'
import { useAuth0 } from '../../Auth0Provider'

export default function Header() {
  const audio = useRef(new Audio(starWarsTheme))
  const [isPlaying, setIsPlaying] = useState(false)
  const { logout } = useAuth0()
  const toggleAudio = () => {
    if (isPlaying) {
      audio.current.pause()
      setIsPlaying(false)
    } else {
      const played = audio.current.play()
      if (played !== undefined) {
        played
          .then(() => {
            setIsPlaying(true)
          })
          .catch(() => {})
      }
    }
  }
  useEffect(() => {
    toggleAudio()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <header className={cls.header}>
      <span onClick={logout}>/thread</span>
      <div className={cls.actions}>
        <button type='button' onClick={toggleAudio}>
          {isPlaying ? <PauseIcon /> : <PlayIcon />}
        </button>
        <span>{isPlaying ? 'Pause music' : 'Play music'}</span>
      </div>
    </header>
  )
}
