import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { getTimeDifference } from '../../utils/time'
import { StatisticsBox } from '../statistics'
import cls from './cash-left.module.css'

export default function CashLeft({ data }) {
  const [countdownData, setCountdown] = useState({
    cashLeft: data.cash_left,
    ...getTimeDifference(
      new Date(),
      moment.tz(data.end_at.date, data.end_at.timezone)
    ),
  })
  const metricsData = [
    {
      value: data.baseline_burn_rate,
      sign: '$',
      title: 'Planned Spend',
      unit: 'month',
    },
    {
      value: data.actual_burn_rate,
      sign: '$',
      title: 'Actual Spend',
      unit: 'month',
    },
    {
      value: data.suggested_burn_rate,
      sign: '$',
      title: 'Need to spend',
      unit: 'month',
    },
  ]
  useEffect(() => {
    const burnRatePerSecond = (data.actual_burn_rate * 12) / 365 / 24 / 60 / 60
    const interval = setInterval(() => {
      const endTime = moment.tz(data.end_at.date, data.end_at.timezone)
      const updatedCountdown = getTimeDifference(new Date(), endTime)
      setCountdown((old) => ({
        cashLeft: old.cashLeft - burnRatePerSecond,
        ...updatedCountdown,
      }))
    }, [1000])
    return () => {
      clearInterval(interval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={cls.container}>
      <h2>Amount of cash left</h2>
      <StatisticsBox
        data={{
          value: countdownData.cashLeft,
          sign: '$',
        }}
      />
      <p>
        <span>{countdownData.months}</span> months,{' '}
        <span>{countdownData.days}</span>days,{' '}
        <span>{countdownData.hours}</span>hours,{' '}
        <span>{countdownData.minutes}</span>min and{' '}
        <span>{countdownData.seconds}</span>
        secs
      </p>
      <div className={cls.details}>
        {metricsData.map((item, index) => (
          <StatisticsBox small data={item} key={index} />
        ))}
      </div>
    </div>
  )
}
