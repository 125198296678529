import React, { useEffect, useMemo } from 'react'
import { useCountDown } from '../../lib/customized-react-countup'
import { classNames } from '../../utils/className'
import { getNumberWithCommas } from '../../utils/number'
import cls from './statistics.module.css'
export function StatisticsValue({ value }) {
  return <span className={cls.statisticsValue}>{value}</span>
}
export function StatisticsDivider({ value }) {
  return <span className={cls.statisticsDivider}>{value}</span>
}
export function StatisticsSign({ value }) {
  return <span className={cls.statisticsSign}>{value}</span>
}
export function StatisticsTitle({ title, unit, small }) {
  return (
    <h6 className={classNames(cls.statisticsTitle, small && cls.small)}>
      {title}
      {unit && <span>/{unit}</span>}
    </h6>
  )
}
export function StatisticsBox({ data, small }) {
  const { title, unit, value, sign } = data
  const countUpRef = React.useRef(null)
  const hookProps = useMemo(
    () => ({
      ref: countUpRef,
      start: value,
      end: value,
      delay: 0,
      separator: ',',
      startOnMount: false,
      duration: 1,
      useEasing: false,
      decimals: 2,
      formattingFn: (val) => {
        const cells = getNumberWithCommas(val, {
          fractionDigits: small ? 0 : 2,
        })
        return (
          <>
            {sign && <StatisticsSign small={small} value={sign} />}
            {cells.map((el, index) =>
              isNaN(el) ? (
                <StatisticsDivider key={index} small={small} value={el} />
              ) : (
                <StatisticsValue key={index} small={small} value={el} />
              )
            )}
          </>
        )
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  const { update } = useCountDown(hookProps)
  useEffect(() => {
    update(value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])
  useEffect(() => {
    countUpRef.current.className = classNames(
      cls.statisticsBoxInner,
      small && cls.small
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div className={cls.statisticsBox}>
      {title && <StatisticsTitle small={small} title={title} unit={unit} />}
      <div ref={countUpRef} />
    </div>
  )
}
