import { useEffect, useState } from 'react'
import { useAuth0 } from './Auth0Provider'
import BurningMoney from './components/burning-money'
import CashLeft from './components/cash-left'
import Footer from './components/footer'
import Header from './components/header'
import Spinner from './components/spinner'
import ErrorPage from './components/error-page'
const fetchData = async (token) => {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/api/burner`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    const data = await res.json()
    return Promise.resolve(data)
  } catch (err) {
    return Promise.reject(err)
  }
}
function App() {
  const [data, setData] = useState()
  const { token } = useAuth0()
  const isLoading = !data || !token
  const getStatistics = async (token) => {
    try {
      const res = await fetchData(token)
      setData(res)
    } catch (err) {
      setData({ message: err.message })
    }
  }
  useEffect(() => {
    if (token) {
      getStatistics(token)
    }
  }, [token])

  if (isLoading) {
    return <Spinner />
  }
  if (!data?.data) {
    return <ErrorPage data={data} />
  }

  const fallingSpeed =
    data.data?.burn.baseline_burn_rate * 1.15 < data.data?.burn.actual_burn_rate
      ? 'fast'
      : data.data?.burn.baseline_burn_rate * 0.85 >
        data.data?.burn.actual_burn_rate
      ? 'slow'
      : 'normal'

  return (
    <div className='main-container'>
      <Header />
      <main>
        <CashLeft data={data.data.burn} />
      </main>
      <BurningMoney fallingSpeed={fallingSpeed} />
      <Footer refetch={() => fetchData(token)} data={data.data.metrics} />
    </div>
  )
}

export default App
