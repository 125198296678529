import React, { useEffect, useMemo, useState } from 'react'
import { useRef } from 'react'
import { useCountDown } from '../../lib/customized-react-countup'
import { divideThousands } from '../../utils/number'
import cls from './footer.module.css'

const FooterStatistic = ({ data }) => {
  const countUpRef = useRef()
  const firstRender = useRef(true)
  const hookProps = useMemo(
    () => ({
      ref: countUpRef,
      start: 0,
      end: data.value,
      delay: 3,
      redraw: false,
      startOnMount:false,
      duration: 2,
      preserveValue: true,
      formattingFn: divideThousands
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  const { update, start } = useCountDown(hookProps)

  useEffect(() => {
    if(firstRender.current){
      start()
      firstRender.current =false
    }
    else {
      update(data.value)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.value])
  return (
    <div className={cls.item}>
      <p className={cls.itemName}>{data.title}</p>
      <span className={cls.itemValue}>
        {data.title === 'MRR' && '$'}
        <span ref={countUpRef} />
      </span>
    </div>
  )
}
export default function Footer({ data, refetch }) {
  const [metrics, setMetrics] = useState(data)
  useEffect(() => {
    const interval = setInterval(() => {
      refetch()
        .then((res) => {
          if (res?.data?.metrics) {
            setMetrics(res?.data?.metrics)
          }
        })
        .catch((err) => {
          console.log('Error while fetching', err)
        })
    }, 10 * 1000)
    return () => {
      clearInterval(interval)
    }
  }, [data, refetch])
  return (
    <footer className={cls.footer}>
      <p className={cls.quote}>“Forged through fire and sweat”</p>
      <div className={cls.inner}>
        {metrics.map((item) => (
          <FooterStatistic key={item.title} data={item} />
        ))}
      </div>
    </footer>
  )
}
