import createAuth0Client from '@auth0/auth0-spa-js'
import { createContext, useContext, useEffect, useState } from 'react'

const DEFAULT_REDIRECT_CALLBACK = () => {
  window.history.replaceState({}, document.title, `${window.location.origin}`)
}

const config = {
  domain: process.env.REACT_APP_AUTH_DOMAIN,
  client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
  audience: process.env.REACT_APP_AUTH_AUDIENCE,
  redirect_uri: window.location.origin,
  useCookiesForTransactions: true,
}

export const createChatgenieAuth0 = () => createAuth0Client(config)

export const logout = async (...p) => {
  const client = await createChatgenieAuth0()
  client.logout({
    returnTo: window.location.origin,
    ...p,
  })
}

export const Auth0Context = createContext()

export const useAuth0 = () => useContext(Auth0Context)

export const Auth0Provider = ({ children, onRedirectCallback }) => {
  const [token, setToken] = useState()

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createChatgenieAuth0()

      if (window.location.search.includes('code=')) {
        const { appState } = await auth0FromHook.handleRedirectCallback()
        onRedirectCallback(appState)
      }

      let authenticated = await auth0FromHook.isAuthenticated()
      if (authenticated) {
        const auth0Token = await auth0FromHook.getTokenSilently()
        setToken(auth0Token)
      } else {
        auth0FromHook.loginWithRedirect({
          appState: { targetUrl: '/' },
        })
      }
    }
    initAuth0()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Auth0Context.Provider
      value={{
        token,
        logout,
      }}
    >
      {children}
    </Auth0Context.Provider>
  )
}

Auth0Provider.defaultProps = {
  children: '',
  onRedirectCallback: DEFAULT_REDIRECT_CALLBACK,
}
