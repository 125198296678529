import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Auth0Provider } from './Auth0Provider'
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  process.env.NODE_ENV === 'development' ? (
    <Auth0Provider>
      <App />
    </Auth0Provider>
  ) : (
    <React.StrictMode>
      <Auth0Provider>
        <App />
      </Auth0Provider>
    </React.StrictMode>
  )
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
