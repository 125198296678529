import { useRef, useEffect, useLayoutEffect, useCallback } from 'react'
import { CountUp } from './count.min'
export const createCountUpInstance = (el, props) => {
  const {
    decimal,
    decimals,
    duration,
    easingFn,
    end,
    formattingFn,
    numerals,
    prefix,
    separator,
    start,
    suffix,
    useEasing,
    enableScrollSpy,
    scrollSpyDelay,
  } = props

  return new CountUp(el, end, {
    startVal: start,
    duration,
    decimal,
    decimalPlaces: decimals,
    easingFn,
    formattingFn,
    numerals,
    separator,
    prefix,
    suffix,
    useEasing,
    useGrouping: !!separator,
    enableScrollSpy,
    scrollSpyDelay,
  })
}
export const useIsomorphicLayoutEffect =
  typeof window !== 'undefined' &&
  typeof window.document !== 'undefined' &&
  typeof window.document.createElement !== 'undefined'
    ? useLayoutEffect
    : useEffect

export const useEventCallback = (fn) => {
  const ref = useRef(fn)

  // we copy a ref to the callback scoped to the current state/props on each render
  useIsomorphicLayoutEffect(() => {
    ref.current = fn
  })

  return useCallback((...args) => ref.current.apply(void 0, args), [])
}
