/* eslint-disable eqeqeq */
export function abbreviateNumber(value) {
  var newValue = value
  if (value >= 1000) {
    var suffixes = ['', 'k', 'm', 'b', 't']
    var suffixNum = Math.floor(('' + value).length / 3)
    var shortValue = ''
    for (var precision = 2; precision >= 1; precision--) {
      shortValue = parseFloat(
        (suffixNum != 0
          ? value / Math.pow(1000, suffixNum)
          : value
        ).toPrecision(precision)
      )
      var dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g, '')
      if (dotLessShortValue.length <= 2) {
        break
      }
    }
    if (shortValue % 1 != 0) shortValue = shortValue.toFixed(1)
    newValue = shortValue + suffixes[suffixNum]
    return { shortValue, suffix: suffixes[suffixNum] }
  }
  return { shortValue: newValue, suffix: '' }
}
export function getNumberWithCommas(
  x,
  { asArray = true, fractionDigits = 2 } = {}
) {
  const result = x
    .toFixed(fractionDigits)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return asArray ? result.split('') : result
}
export function makeDoubleDigit(num) {
  return num > 9 ? num : `0${num}`
}
export const divideThousands = (num) => {
  const validated = Number(num) || 0
  return validated.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}