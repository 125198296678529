import React from 'react'
import cls from './index.module.css'
import FireIcon from './FireIcon'
import FallingMoney from './FallingMoney'
export default function BurningMoney({ fallingSpeed }) {
  return (
    <div className={cls.container}>
      <FallingMoney fallingSpeed={fallingSpeed} />
      <FireIcon />
    </div>
  )
}
