import moment from 'moment'
import { makeDoubleDigit } from './number'

export const getMonthsDifference = (startTime, endTime) => {
  const now = moment(startTime)
  const end = moment(endTime)
  const months = Math.floor(end.diff(now, 'months'))
  return months
}
export function getTimeDifference(startTime, endTime) {
  const start = moment(startTime)
  const end = moment(endTime)

  const months = makeDoubleDigit(Math.floor(end.diff(start, 'months')))
  start.add(months, 'months')
  const days = makeDoubleDigit(Math.floor(end.diff(start, 'days')))
  start.add(days, 'days')
  const hours = makeDoubleDigit(Math.floor(end.diff(start, 'hours')))
  start.add(hours, 'hours')
  const minutes = makeDoubleDigit(Math.floor(end.diff(start, 'minutes')))
  start.add(minutes, 'minutes')
  const seconds = makeDoubleDigit(Math.floor(end.diff(start, 'seconds')))
  start.add(seconds, 'seconds')
  return {
    months,
    days,
    hours,
    minutes,
    seconds,
  }
}
