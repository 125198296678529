import React from 'react'
import cls from './index.module.css'
import noAccessMeme from '../.././assets/images/no_acess_meme.jpeg'
export default function ErrorPage({ data }) {
  return (
    <div className={cls.container}>
      <div className={cls.inner}>
        <img src={noAccessMeme} alt='no access meme' />
        <p>
          {data?.status === 403
            ? 'It seems you have no access to view app'
            : data?.message || 'Something went wrong!'}
        </p>
      </div>
    </div>
  )
}
